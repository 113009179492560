import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { db, auth } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { ValidaLinha } from "../components/Validacao";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Logo from "../assets/logo2.png";
import moment from "moment";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [dados, setDados] = useState({});

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const getData = async () => {
    const qry = await getDoc(doc(db, "desconto2025", uid));

    const data = qry.data();

    setDados(data);
    setLoading(false);
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {loading && <Loading />}

      {!loading && !dados?.status && <Novo />}
      {!loading && dados?.status === "e" && <Incorreto dados={dados} />}
      {!loading && dados?.status === "p" && <Pendente />}
      {!loading && dados?.status === "i" && <Inativo />}
      {!loading && dados?.status === "c" && <Cancelado />}
      {!loading && dados?.status === "a" && <Aprovado dados={dados} />}
    </>
  );
};

export default Home;

const Aprovado = (props) => {
  return (
    <Frame
      body={
        <div className="container-fluid">
          <div className="col-sm-6 mx-auto">
            <img className="mt-2" src={Logo} width={250} alt="logo" />
            <h4 className="mt-2 py-1 bg-danger bg-gradient text-white rounded-1">
              DESCONTO 10%
            </h4>

            <div className="mt-2">
              <QRCode size={150} value={"378|" + props.dados.uid} />
            </div>

            <img
              src={props.dados.urlFoto}
              alt="Usuário"
              width="150"
              height="150"
              className="d-inline rounded-circle mt-2"
            />

            <div className="mt-2">
              <h4 className="mt-2 py-1 bg-danger bg-gradient text-white rounded-1">
                {props.dados.nome}
              </h4>
            </div>
            <div className="mt-2 mx-auto fs-5">
              <div className="mt-1">
                <span className="fw-bold azul-logo">Carteira: </span>
                {props.dados.contrato}
              </div>
              <div className="mt-1">
                <span className="fw-bold azul-logo">CPF: </span>
                {props.dados.cpf}
              </div>
              <div className="mt-1">
                <span className="fw-bold azul-logo">RG: </span>
                {props.dados.rg}
              </div>
              <div className="mt-1">
                <span className="fw-bold azul-logo">Validade: </span>
                {moment(props.dados.validade).format("DD/MM/YYYY")}
              </div>
              {props.dados.linha1 && (
                <div className="fw-bold azul-logo mt-1">
                  {ValidaLinha(props.dados.linha1)}
                </div>
              )}
              {props.dados.linha2 && (
                <div className="fw-bold azul-logo mt-1">
                  {ValidaLinha(props.dados.linha2)}
                </div>
              )}
              {props.dados.linha3 && (
                <div className="fw-bold azul-logo mt-1">
                  {ValidaLinha(props.dados.linha3)}
                </div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

const Novo = () => {
  return (
    <Frame
      body={
        <div className="p-3 text-start">
          <p>
            Estudantes e professores que residem em cidade diferente da
            instituição de ensino têm direito ao benefício de 10% de desconto na
            linha utilizada para seu deslocamento.
          </p>
          <p>
            Cadastre seus dados a seguir para emitir sua carteirinha digital de
            desconto estudantil Expresso Embaixador.
          </p>

          <div className="mt-4 text-end">
            <a className="btn btn-primary" href="./form1">
              Prosseguir
            </a>
          </div>
        </div>
      }
      title="Faça seu Cadastro"
    />
  );
};

const Pendente = () => {
  return (
    <Frame
      body={
        <div className="justify p-3">
          <p>
            Sua solicitação do benefício foi recebida. Agora, vamos validar as
            informações enviadas e, caso encontremos alguma inconformidade,
            iremos informá-lo aqui no portal.
          </p>

          <div className="alert alert-warning text-center mt-4" role="alert">
            Por favor, verifique o portal novamente a partir do próximo dia
            útil.
          </div>
        </div>
      }
      title="Cadastro em Análise"
    />
  );
};

const Incorreto = (props) => {
  return (
    <Frame
      body={
        <div className="p-3 text-start">
          <p>
            Encontramos algumas inconformidades durante a validação da sua
            solicitação do benefício.
          </p>

          <p>
            Abaixo, seguem as instruções sobre o que deve ser ajustado. Por
            gentileza, ajuste os dados e reenvie-os.
          </p>
          <div className="alert alert-danger mt-4 text-center" role="alert">
            {props.dados.observacao}
          </div>

          <div className="mt-4 text-end">
            <a className="btn btn-primary" href="./form1">
              Ajustar
            </a>
          </div>
        </div>
      }
      title="Verifique seus dados"
    />
  );
};

const Inativo = () => {
  return (
    <Frame
      body={
        <div>
          <p>Seu cadastro encontra-se inativo.</p>
          <p>Para reativá-lo, entre em contato conosco.</p>
          <p>Whatsapp: (53) 99125-5743</p>
        </div>
      }
      title="Cadastro Inativo"
    />
  );
};

const Cancelado = () => {
  return (
    <Frame
      body={
        <div>
          <p>Seu cadastro conosco encontra-se cancelado.</p>
          <p>Para reativá-lo, entre em contato conosco.</p>
          <p>Whatsapp: (53) 99125-5743</p>
        </div>
      }
      title="Cadastro Cancelado"
    />
  );
};
